@import url('https://fonts.googleapis.com/css2?family=Mulish&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Mulish', sans-serif;
}

.navbar {
    background-color: #fff !important;
}

.navbar-brand {
    font-size: 1.3rem !important;
    color: #565387 !important;
}

.navbar a {
    font-size: 1.3 rem;
    text-transform: capitalize;
}

.menu_active {
    font-weight: bold;
    border-bottom: 1px solid #565387;
}

.navbar a:hover{
    color :#3498db !important;
}

.foo{
    min-height: 100vh;
}

.set {
    /*height: 380px;*/
    /*width: 500px;*/
    max-width: 200px;
    margin: 0 auto;
}

@media (max-width: 800px) {
    .navbar-brand {
        font-size: 1.5rem !important;
    }

    .menu_active{
        font-weight: bold;
        border-bottom: none;
    }

    .set {
        /*height: 280px;*/
        /*width: 260px;*/
        max-width: 300px;
    }
}

#header {
    width: 100%;
    min-height: 80vh;
    margin-top: 70px;
}
@media (min-width: 1228px) {
    #header {margin-top: 0px;}
}
#header h1 {
    margin: 0 0 10px 0;
    font-size: 22px;
    font-weight: 700;
    color: black;
}

#header .brand-name {
    color: #3498db;
}

#header h2{
    color: #484848;
    font-size: 24px;
}

#header .btn-get-started {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 30px;
    border-radius: 50px;
    transition: 0.5s;
    color: #3498db;
    border: 2px solid #3498db;
    text-decoration: none;
}

#header .btn-get-started:hover{
    background: #3498db;
    color: #fff;
}

.header-img {
    text-align: center;
}

#header .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@keyframes up-down{
    0% {
        transform: translateY(10px);
    }
    100% {
        transform: translateY(-10px);
    }
}
#header .img-slider.img-slider2{
    margin-left: 15px;
    margin-right: 15px;
}
#header .img-slider{
    display: inline-block;
    max-height: 500px;
}
/*@media (max-width: 1228px) and (min-width: 991px) {*/
/*    #header .img-slider2{*/
/*        display: none;*/
/*    }*/
/*}*/
@media (max-width: 992px) {
    #header .img-slider{
        max-height: 350px;
    }
}
@media (max-width: 768px) {
    #header .img-slider{
        max-height: 200px;
    }
}
#header .img-slider1, #header .img-slider3{
    max-height: 150px;
}
footer{
    margin-top: 30px;
}
#header .gp-btn{
    padding: 0;
}
.logo {
    /*opacity: 0.5;*/
    /*margin-top: 10px;*/
    /*background: rgba(0,0,0,0.7);*/
    padding: 2px 6px;
    border-radius: 4px;
}
.welcome-page .logo{
    background: rgba(0,0,0,0.7);
}
.welcome-page  .navbar.top-navbar a{
    color: #fff !important;
}
.welcome-page  .navbar.top-navbar{
    background: rgba(0,0,0,0.7);
    padding: 2px 6px;
    border-radius: 4px;
    background-color: rgba(0,0,0,0.7) !important;
}
.logo h1{
    font-size: 24px;
}
.logo span:nth-child(2){
    font-size: 20px;
    font-weight: bold;
    color: #3dc2ff;
}
.logo span:nth-child(1){
    color: #5260ff;
}
.logo span{
    font-size: 22px;
    text-transform: uppercase;
    display: block;
    text-align: left;
}
.logo img{
    display: inline-block;
    /*opacity: 0.5;*/
}
@media (min-width: 768px) {
    .logo h1{
        margin-left: 40px;
    }
}
.footer-links li{
    /*padding: 5px;*/
}
.footer-links li a{
    color: #000;
}
@media (max-width: 768px) {
    .footer-links li{
       display: block;
    }
}
.wa-cta b{
    padding-left: 8px;
}
.wa-cta{
    padding: 2px;
    margin-left: 10px;
}
.list-inline-item:not(:last-child) {
    margin-right: 0.9rem;
}
@keyframes gradientAnim {
    0% {
        background-position: -5% 0;
    }
    100% {
        background-position: 105% 0;
    }
}
* {
    box-sizing: border-box;
}
.video-header {
    /*position: absolute;*/
    text-align: center;
    width: 100vw;
    height: 100vh;
}
.video-header, .video-header video, .video-header .viewport-header {
    width: 100vw;
    height: 100vh;
    /*position: absolute;*/
    /*top: 0;*/
    /*left: 0;*/
}
.video-header video {
    background: transparent;
    object-fit: cover;
    /*opacity: 0.5;*/
}
.video-header .viewport-header {
    display: flex;
    align-items: center;
    justify-content: center;
}
.navbar6 {
    /*height: 100vh;*/
    /*width: 100vw;*/
    /*margin: 0;*/
    /*padding: 0;*/
    /*display: flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*background-color: #f23064;*/
    background-color: #fff;
    /* Old browsers */
    background-image: -moz-linear-gradient(left, #fff 0%, #d8bce8 12.5%, #b496d3 25%, #c8d4e0 37.5%, #C7F2B2 50%, #67f230 62.5%, #e2e22b 75%, #ce8027 87.5%, #fff 100%);
    /* FF3.6-15 */
    background-image: -webkit-linear-gradient(left, #fff 0%, #d8bce8 12.5%, #b496d3 25%, #c8d4e0 37.5%, #C7F2B2 50%, #67f230 62.5%, #e2e22b 85.5%, #ce8027 87.5%, #fff 100%);
    /* Chrome10-25,Safari5.1-6 */
    background-image: linear-gradient(to right, #fff 0%, #67f230 12.5%, #b496d3 25%, #c8d4e0 37.5%, #C7F2B2 50%, #67f230 62.5%, #e2e22b 75%, #ce8027 87.5%, #ce8027 93.5%, #fff 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    background-repeat: no-repeat;
    background-size: 2000% 400%;
    background-position: 0 0;
    animation-name: gradientAnim;
    animation-iteration-count: 1;
    animation-delay: 0;
    animation-duration: 6s;
    animation-timing-function: linear;
}

h1 {
    /*color: #fff;*/
    /*font-family: "Segoe UI", "San Franscisco", Calibri, Helvetica, Arial, sans-serif;*/
    /*font-weight: 100;*/
    /*font-size: calc(100% + 5vmax);*/
}
.intro, .navbar{
    /*position: absolute;*/
    /*top: 0;*/
    z-index: 10;
}
.intro h1, .intro p{
    background: rgba(0,0,0,0.5);
    padding: 5px;
}
.top-banner{
    display: none;
}
.welcome-page .top-banner p b{
    color: red !important;
}
.welcome-page .top-banner p{
    text-transform: uppercase;
    color: white !important;
    padding: 0;
    margin: 0;
}
.welcome-page .top-banner{
    display: block;
    z-index: 20;
    text-align: center;
    position: relative;
    background: #000;
}
.welcome-page footer{
/*position: absolute;*/
bottom: 0;
z-index: 11;
margin-bottom: -250px;
}
@media (max-width: 768px) {
.welcome-page footer{
    margin-bottom: -400px;
}
.apps-links button{
    display: block;
    margin: 0 auto;
}
}
@media (max-width: 500px) {
.welcome-page footer{
    margin-bottom: -600px;
}
}
@media (max-width: 300px) {
.welcome-page footer{
    margin-bottom: -800px;
}
}
.feature-card .card-title{
    font-size: 24px;
}
.feature-card{
    margin-bottom: 80px;
}
.feature-txt{
    padding-top: 150px;
}
#promovideo{

}
#promovideo h4:not(:nth-child(3)){
    margin: 0 auto;
    display: block;
    text-align: center;
    padding: 60px 0 45px 0;
    border-top: 1px solid #f2f0d0;
    padding-top: 20px;
    margin-top: 40px;
}
#promovideo video{
    max-width: 300px;
    margin: 0 auto;
    display: block;
}
.bottom-links .apps-links, .bottom-links h3{
    margin-top: 100px;
    text-align: center;
}
.bottom-links .apps-links{

    margin-bottom: 100px;
}
.bottom-links h3{
    border-top: 1px solid #ccc;
    padding-top: 20px;
    font-size: 36px;
}
.mw300{
    max-width: 300px;
    margin: 0 auto;
    display: block;
}
